.content-container {
  background-attachment: fixed;
  background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url("../../backgrounds/background-light-grey.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2000px;
  border-radius: 30px;
  color: white;
  display: flex;
  margin-bottom: 120px;
  text-shadow: black 8px 8px 25px;
  width: auto;
}

.description-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 350px;
}

.description {
  padding: 0 20px;
}

.icon-container {
  align-items: center;
  display: flex;
  justify-content: space-around;
  margin-top: 15px;
}

.icon {
  width: 50px;
}

.project-sample-image {
  max-height: 500px;
  max-width: 500px;
  padding: 30px;
}

.image-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 1000px) {
  .content-container {
    flex-direction: column;
  }

  .description-container {
    align-self: center;
    width: 475px;
  }
}

@media only screen and (max-width: 600px) {
  .content-container {
    width: 450px;
  }

  .project-sample-image {
    max-width: 400px;
  }

  .description-container {
    align-self: center;
    width: 450px;
  }
}

@media only screen and (max-width: 450px) {
  .content-container {
    width: 350px;
  }

  .project-sample-image {
    max-width: 300px;
  }

  .description-container {
    align-self: center;
    width: 300px;
  }
}
