@import url("https://fonts.googleapis.com/css?family=Nanum+Myeongjo&display=swap");

* {
  /* border: red solid 1px; */
}

body {
  height: 100%;
  font-family: "Nanum Myeongjo", serif;
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.down-arrow {
  width: 30px;
  margin-bottom: 200px;
  background-color: white;
}

.about-title {
  margin-bottom: 5px;
  font-size: 30px;
}
