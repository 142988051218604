.about-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 600px;
  margin-bottom: 70px;
}

.profile-photo {
  width: 500px;
  border-radius: 250px;
  margin-bottom: 30px;
}

.skill {
  list-style-type: none;
  margin: 8px;
}

@media only screen and (max-width: 600px) {
  .about-container {
    width: 450px;
  }
}

@media only screen and (max-width: 450px) {
  .about-container {
    width: 350px;
  }

  .profile-photo {
    width: 350px;
  }
}