.contract-container {
  background-attachment: fixed;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.5),
      rgba(255, 255, 255, 0.5)
    ),
    url("../../backgrounds/background-light-grey.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: 2000px;
  border-radius: 30px;
  display: flex;
  margin-bottom: 120px;
  text-shadow: white 8px 8px 25px;
  width: auto;
}

.contract-info-container {
  display: flex;
  flex-direction: column;
  margin: 20px;
  width: 350px;
}

.contract-image-container {
  align-items: center;
  display: flex;
  justify-content: center;
}

.contract-image {
  max-height: 400px;
  max-width: 400px;
  padding: 30px;
}

@media only screen and (max-width: 1000px) {
  .contract-container {
    flex-direction: column;
  }

  .contract-image {
    align-self: center;
    max-width: 475px;
  }

  .contract-info-container {
    align-self: center;
    width: 475px;
  }
}

@media only screen and (max-width: 600px) {
  .contract-container {
    width: 450px;
  }

  .contract-image {
    max-width: 350px;
  }

  .contract-info-container {
    align-self: center;
    width: 350px;
  }
}

@media only screen and (max-width: 450px) {
  .contract-container {
    width: 350px;
  }

  .contract-image {
    max-width: 300px;
  }

  .contract-info-container {
    align-self: center;
    width: 300px;
  }
}
